import React from 'react'
import {
  withRouter,
  Switch,
  Route,
  Redirect,
  BrowserRouter as Router
} from 'react-router-dom'

// import { Authentication } from './Authentication'
import SignIn from './SignIn'
import Main2 from '../views/Main2'
import Calculator from '../views/Calculator'
import Menu from '../views/Menu'
import Uploader from '../views/Uploader'
import Form from '../views/Form'
import Form2 from '../views/Form2'
import Admin from '../views/Admin'
import GlobalUploader from '../views/GlobalUploader'
import { Context } from '../AppContext'

class PrivateRoute extends React.Component {
  static contextType = Context

  state = {
    loaded: false,
    isAuthenticated: false
  }

  async componentDidMount() {
    const { store, actions } = this.context
    await actions.authMe()

    if (store.currentUser) {
      if (!this.state.isAuthenticated) {
        this.setState({ loaded: true, isAuthenticated: true })
      }
    } else {
      this.props.history.push('/auth')
    } 
  }

  render() {
    const { component: Component, ...rest } = this.props
    const { loaded , isAuthenticated} = this.state
    if (!loaded) return null
    return (
      <Route
        {...rest}
        render={props => {
          return isAuthenticated ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/auth",
              }}
            />
          )
        }}
      />
    )
  }
}

PrivateRoute = withRouter(PrivateRoute)

const Routes = () => (
  <Router>
    <Switch>
      <Route path='/auth' component={SignIn} />
      <PrivateRoute path='/main' component={Main2} />
      <PrivateRoute path='/menu' component={Menu} />
      <PrivateRoute path='/uploader' component={Uploader} />
      <PrivateRoute path='/indicadores' component={GlobalUploader} />
      <PrivateRoute path='/calculator' component={Calculator} />
      <PrivateRoute path='/form' component={Form} />
      <PrivateRoute path='/form2' component={Form2} />
      <PrivateRoute path='/admin' component={Admin} />
      <Redirect from='/' to='/menu' />
      {/* <PrivateRoute path='/' component={Authentication} /> */}
    </Switch>
  </Router>
)

export default Routes