import styled from 'styled-components'


const StyledTitle = styled.h3`
  color: #353a40;
  font-size: 26px;
  margin: 40px 130px;
  display: inline;
  
  ${(props) =>
    props.another &&
  `
    display: flex;
    justify-content: center;
    font-weight: 900;
    
    
    `}

${(props) =>
    props.menu &&
  `
    margin: 0;
    
    
    `}

  ${(props) =>
    props.secondary &&
  `
    display: inline;
    text-align: center;
    margin-left: 20px;
    margin-left: 30px;
    font-weight: normal;
    font-size: 22px;
    
    `}

${(props) =>
    props.third &&
  `
    display: inline;
    text-align: right;
    margin-left: 0px;
    font-weight: normal;
    font-size: 22px;
    
    `}
`

export default StyledTitle;