import React, { useContext, useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";

import { makeStyles } from "@material-ui/core/styles";
import { Context } from "../AppContext";
import MainNavbar from "../components/MainNavbar";
import Pdf from "../components/Pdf";
import Container from "@material-ui/core/Container";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(2),
      width: "25ch",
    },
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 220,
  },
  root2: {
    margin: "auto",
    width: "10%",
    padding: "10px",
  },
}));

export default function Form() {
  const classes = useStyles();
  const { store, actions } = useContext(Context);
  const [data, setData] = useState({


    ciudad: store.ciudad,
    region: store.region,
    email: store.email,

    jperiods: store.afiliadosCalculo,
    pperiods: store.afiliadosCalculoPre,
    paymentdate: store.date,
    celNumber: '',
    nombreRepLegal: '',
    rutRepLegal: '',
    fondoPensiones: 'Efectivo',
    domicilio: '',
    comuna: '',
    razonSocial: '',
    idEmpleador: '',
    codEconomica: '121212',
    employer: '',
    tipoPago: 'total',
    folio: '',
  })
  console.log(store)
  console.log(data)

  useEffect(() => {
    data.folio = JSON.parse(JSON.stringify(store.folio))
    setData({ ...data, ...store.afiliados })
    console.log(store)
    console.log(data)
    // console.log(data, store.afiliados)
  }, [])

  const handleChange = e => {
    const { name, value } = e.target;
    console.log(store)
    console.log(data)
    setData({ ...data, [name]: value });
  };

  return (
    <>
      <MainNavbar />
      {store.loading && (
        <div className={classes.root2}>
          <CircularProgress />
        </div>
      )}
      {!store.loading && (
        <Container maxWidth="lg">
          <form className={classes.root} noValidate autoComplete="off">
            <div>
              <TextField
                id="outlined-helperText"
                label="Razón Social"
                name='razonSocial'
                defaultValue={data.razonSocial}
                onChange={(e) => handleChange(e)}
                variant="filled"
              />
              <TextField
                disable
                id="outlined-helperText"
                label="RUT Empleador"
                name='idEmpleador'
                defaultValue={data.idEmpleador}
                onChange={(e) => handleChange(e)}
                variant="filled"
              />
              <TextField
                id="outlined-helperText"
                label="Dirección"
                name="domicilio"
                defaultValue={data.domicilio}
                variant="filled"
                onChange={(e) => handleChange(e)}
              />
              <TextField
                id="outlined-helperText"
                label="Comuna"
                name="comuna"
                defaultValue={data.comuna}
                variant="filled"
                onChange={(e) => handleChange(e)}
              />
              <TextField
                id="outlined-helperText"
                label="Ciudad"
                name="ciudad"
                variant="filled"
                onChange={(e) => handleChange(e)}
              />
              <TextField
                id="outlined-helperText"
                label="Región"
                name="region"
                variant="filled"
                onChange={(e) => handleChange(e)}
              />
              <TextField
                id="outlined-helperText"
                label="Correo Electrónico"
                name="email"
                defaultValue={data.email}
                variant="filled"
                onChange={(e) => handleChange(e)}
              />
              <TextField
                id="outlined-helperText"
                label="Numero telefónico"
                name="celNumber"
                variant="filled"
                onChange={(e) => handleChange(e)}
              />
              <TextField
                id="outlined-helperText"
                label="Representante Legal"
                name="nombreRepLegal"
                defaultValue={data.nombreRepLegal}
                variant="filled"
                onChange={(e) => handleChange(e)}
              />
              <TextField
                id="outlined-helperText"
                label="RUT Representante Legal"
                name="rutRepLegal"
                defaultValue={data.rutRepLegal}
                variant="filled"
                onChange={(e) => handleChange(e)}
              />
              <FormControl variant="filled" className="MuiTextField-root">
                <InputLabel id="demo-simple-select-filled-label">fondoPensiones</InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="outlined-helperText"
                  name="fondoPensiones"
                  value={data.fondoPensiones}
                  onChange={(e) => handleChange(e)}
                >
                  <MenuItem value="">
                    <em>Vacio</em>
                  </MenuItem>
                  <MenuItem value={"Efectivo"}>Efectivo</MenuItem>
                  <MenuItem value={"Cheque"}>Cheque</MenuItem>
                </Select>
              </FormControl>

              {/* <TextField
                id="outlined-helperText"
                label="fondoPensiones"
                name="fondoPensiones"
                defaultValue={data.fondoPensiones}
                variant="filled"
                onChange={(e) => handleChange(e)}
              /> */}
              {/* <TextField
                id="outlined-helperText"
                label="numeroFolio"
                name="numeroFolio"
                defaultValue={data.numeroFolio}
                variant="filled"
                onChange={(e) => handleChange(e)}
              /> */}
              <TextField
                id="outlined-helperText"
                label="codEconomica"
                name="codEconomica"
                defaultValue={data.codEconomica}
                variant="filled"
                onChange={(e) => handleChange(e)}
              />
              {/* <TextField
                id="outlined-helperText"
                label="employer"
                name="employer"
                defaultValue={data.employer}
                variant="filled"
                onChange={(e) => handleChange(e)}
              /> */}
              {/* <TextField
                id="outlined-helperText"
                label="paymentdate"
                name="paymentdate"
                defaultValue={data.paymentdate}
                variant="filled"
                onChange={(e) => handleChange(e)}
              /> */}
              {/* <TextField
                id="outlined-helperText"
                label="jperiods"
                name="jperiods"
                defaultValue={data.jperiods}
                variant="filled"
                onChange={(e) => handleChange(e)}
              /> */}
              {/* <TextField
                id="outlined-helperText"
                label="pperiods"
                name="pperiods"
                defaultValue={data.pperiods}
                variant="filled"
                onChange={(e) => handleChange(e)}
              /> */}
            </div>
          </form>
          <Pdf data={data} />
        </Container>
      )}
    </>
  );
}
