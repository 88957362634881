import React, { useContext } from "react";
import { Context } from "../AppContext";
import { DropzoneArea } from "material-ui-dropzone";
import MainNavbar from "../components/MainNavbar";
import Container from "@material-ui/core/Container";
import StyledButton from "../styles/StyledButton";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function Uploader() {
  const { store, actions } = useContext(Context);
  return (
    <div>
      <MainNavbar />
      {store.loader && (
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <CircularProgress />
        </div>
      )}
      {!store.loader && 
      <>
      <Container maxWidth="md" style={{marginBottom: '40px'}}>
        <DropzoneArea
          dropzoneText={"Agrega el archivo de asignaciones aquí o haz click"}
          name='asignacion'
          onChange={(e) => actions.handleFile(e)}
          acceptedFiles={['.xlsx']}
        >
        </DropzoneArea>
        <StyledButton primary pdf onClick={(e) => actions.postAsignacion()}>Enviar</StyledButton>
        </Container>
        <Container maxWidth="md" style={{marginBottom: '40px'}}>
        <DropzoneArea
          dropzoneText={"Agrega el archivo de pagos aquí o haz click"}
          onChange={(e) => actions.handleFilePago(e)}
        
        >
        </DropzoneArea>
        <StyledButton primary pdf onClick={(e) => actions.postPago()}>Enviar</StyledButton>
        </Container>
        <Container maxWidth="md" style={{marginBottom: '40px'}}>
        <DropzoneArea
          dropzoneText={"Agrega el archivo de intereses aquí o haz click"}
          onChange={(e) => actions.handleFileInteres(e)}
        >
        </DropzoneArea>
        <StyledButton primary pdf onClick={(e) => actions.postInteres()} >Enviar</StyledButton>
      </Container>
      </>
      }
    </div>
  );
}
