import React, { useState, useEffect, useContext } from "react";
import {Context} from '../AppContext';
import { Link } from "react-router-dom";
import { Navbar } from "styled-navbar-component";
import { Nav } from "styled-nav-component";
import SignOut from "./SignOut";
import Grid from '@material-ui/core/Grid';
import logo from "./logohmycia.jpg";
import "./signin.css";

export default function MainNavbar() {
  const [user, setUser] = useState();
  const { store, actions } = useContext(Context)

  useEffect(() => {
    setUser(store.currentUser)
  }, [store]);

  return (
    <div>
      <Navbar expandXl dark style={{ marginBottom: "30px" }}>
        <Nav start="true">
          <Link to="/menu">
            <img
              src={logo}
              alt=""
              className="logo-principal"
              width="200px"
              height="20px"
              style={{marginTop: '10px'}}
            />
          </Link>
          <Nav end="true">
            <Grid container  justify="flex-end" direction='row' alignItems='center'>
            <Grid item xs={3} >
            {user && <h3>{`Bienvenido ${user}`}</h3>}
            </Grid>
            <Grid item xs={2}>
            <SignOut />
            </Grid>
            </Grid>
          </Nav>
        </Nav>
      </Navbar>
    </div>
  );
}
