import styled from "styled-components";

const StyledButton = styled.button`
  display: inline-block;
  font-weight: 700;
  font-size: 16px;
  padding: 8px 9px;
  color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-transform: translateY(0);
  transform: translateY(0);

  &:hover {
    -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
  -webkit-transition: all .4s ease;
  transition: all .4s ease
  }

  ${(props) =>
    props.primary &&
    `
    background-color: #25AAE2;
    border-color: #25AAE2;
    &:hover {
      background-color: #148ba1;
      border-color: #148ba1
    }
    `}

  ${(props) => 
  props.pdf && 
  `
  display: inline-block;
  position: absolute;
  right: 0px;
  margin-right: 20px;
  
  `}

`;

export default StyledButton;
