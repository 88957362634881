import styled from "styled-components";

const StyledTotp = styled.input`
  display: inline-block;
  margin: 5px 1rem;
  color: black;
  border: 2px solid #25AAE2;
  padding: 5px;
  padding-left: 20px;
  width: 120px;
  height: 40px;
  background: lightgray;
  letter-spacing: 5px;
  font-size: 22px;

  ${(props) =>
    props.rutEmpresa &&
  `
    margin-left: 250px;
    margin-right: 10px;
	  padding-left: 5px;	
	  height: 20px;
	  width: 130px;
    letter-spacing: 0;
	  font-size: 14px;
    `}

${(props) =>
    props.rut &&
  `
    
	  padding-left: 5px;	
	  height: 20px;
	  width: 130px;
    letter-spacing: 0;
	  font-size: 14px;
    `}
`;

export default StyledTotp;
