import React, { forwardRef, useContext, useEffect } from "react";
import { Context } from "../AppContext";
import { makeStyles } from "@material-ui/core/styles";
import MainNavbar from "../components/MainNavbar";
import MaterialTable from "material-table";
import Container from "@material-ui/core/Container";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import CircularProgress from "@material-ui/core/CircularProgress";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
    width: "10%",
    padding: "10px",
  },
}));

export default function Admin() {
  const { store, actions } = useContext(Context);
  const classes = useStyles();
  const [state, setState] = React.useState({
    columns: [
      { title: "Email", field: "user" },
      { title: "Permisos", field: "object" },
    ],

    data: [],
  });

  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    if (store.users) {
      setLoading(false);
    }
  }, [store.users]);

  useEffect(() => {
    actions.getUsers();
    console.log("OJO", store.users);
  }, [store.resp]);

  return (
    <>
      <MainNavbar />
      {loading && (
        <div className={classes.root}>
          <CircularProgress />
        </div>
      )}
      {!loading && (
        <Container maxWidth="md">
          <MaterialTable
            icons={tableIcons}
            title="Usuarios"
            columns={state.columns}
            data={store.users}
            options={{
              search: false,
            }}
            editable={{
              onRowAdd: (newData) =>
                new Promise((resolve) => {
                  actions.addUser(newData);
                  setTimeout(() => {
                    resolve();
                    setState((prevState) => {
                      const data = [...store.users];
                      data.push(newData);
                      return { ...prevState, ...store.users };
                    });
                  }, 600);
                }),
              onRowDelete: (oldData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    actions.deleteUser(oldData);
                    setState((prevState) => {
                      const data = [...store.users];
                      data.splice(data.indexOf(oldData), 1);
                      return { ...prevState, ...store.users };
                    });
                  }, 600);
                }),
            }}
          />
        </Container>
      )}
    </>
  );
}
