import React, { useContext } from 'react';
import StyledButton from '../styles/StyledButton';
import { Context } from '../AppContext'
import { withRouter } from 'react-router-dom';

function SignOut(props) {
  const { actions } = useContext(Context)

  return (
    <div>
      <StyledButton primary onClick={() => {
        actions.logout()
        props.history.push('/auth')
      }}>Cerrar Sesión</StyledButton> 
    </div>
  )
}

export default withRouter(SignOut)
