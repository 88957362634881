import { createGlobalStyle } from "styled-components"

const StyledBody = createGlobalStyle`
  body {
    background: #efefef;
    
    font-family: 'Roboto', sans-serif;
    margin: 0;
    
  }
`
export default StyledBody;