const getState = ({ getStore, getActions, setStore }) => {
  return {
    store: {
      rutEmpresa: "760497541",
      rut: "",
      rutAfiliado: "",
      afiliados: [],
      afiliadosJudicial: [],
      afiliadosJudicial2: [],
      afiliadosPre2: [],
      detalleJudicial: [],
      detallePre: [],
      afiliadosPre: [],
      afiliados2: [],
      periodoJud: [],
      periodoPre: [],
      filterPeriodo: [],
      filterAfiliados: [],
      afiliadosCalculo: [0],
      afiliadosCalculoPre: [0],
      totalJudicial: 0,
      totalPreJudicial: 0,
      totalReajusteJud: 0,
      totalInteres: 0,
      totalRecargoJud: 0,
      totalRecargoAFPJud: 0,
      totalInteresProyectadoJud: 0,
      recuperoTotalJud: 0,
      totalReajustePre: 0,
      totalInteresPre: 0,
      totalRecargoPre: 0,
      totalRecargoAFPPre: 0,
      totalInteresProyectadoPre: 0,
      recuperoTotalPre: 0,
      interesProyectadoJud: [],
      totalRecuperoJud: [],
      interesJud: [],
      recargoJud: [],
      recargoAFPJud: [],
      reajuste: [],
      interesProyectadoPre: [],
      totalRecuperoPre: [],
      interesPre: [],
      recargoPre: [],
      recargoAFPPre: [],
      reajustePre: [],
      montoNominal: [],
      montoNominalPre: [],
      total: 0,
      totalPago: 0,
      totalPagoPre: 0,
      date: "",
      direccion: "",
      comuna: "",
      ciudad: "",
      region: "",
      email: "",
      celNumber: "",
      representante: "",
      rutRepresentante: "",
      valor: "",
      selected: "",
      asignacion: "",
      pago: "",
      interes: "",
      folio: [],
      users: '',
      permisos: '',
      resp: '',
      loading: true,
      indicador: '',
      loader: false,
      currentUser: '',
    },
    actions: {
      getAfiliados: async (e) => {
        const token = localStorage.getItem('jwt') || null;
        const store = getStore();
        console.log(e);
        const resp = await fetch(
          `https://p136qqy5w2.execute-api.us-west-2.amazonaws.com/prd/listar?employer=${e}`, //lista
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${token}`,
            },
          }
        );
        const dato = await resp.json();
        console.log(dato);
        if (dato !== "Not Found") {
          dato.carteras.map((item) => {
            if (item.id === "Judicial") {
              item.periodos.map((element, i) => {
                item.periodos[i]["selected"] = false;
                return item.periodos;
              });
              setStore({ afiliadosJudicial: item.periodos });
              console.log(store.afiliadosJudicial);
            } else if (item.id === "Prejudicial") {
              item.periodos.map((element, i) => {
                item.periodos[i]["selected"] = false;
                return item.periodos;
              });
              setStore({ afiliadosPre: item.periodos });
              console.log(store.afiliadosPre);
            }
          });
        } else {
          alert("Numero de RUT incorrecto");
        }
      },

      postAsignacion: async (e) => {
        const token = localStorage.getItem('jwt') || null;
        const store = getStore();
        setStore({ loader: true })
        var formData = new FormData();
        formData.append("file", store.asignacion[0]);
        formData.append("option", "asignacion");
        console.log(formData.getAll('file'));
        const resp = await fetch(
          `https://p136qqy5w2.execute-api.us-west-2.amazonaws.com/prd/sistema/upload`, //upload
          {
            method: "POST",
            headers: {
              'Authorization': `Bearer ${token}`,
            },
            body: formData,
          }
        );
        const dato = await resp.text();
        alert(dato.message);
        setStore({ loader: false })
      },

      postPago: async (e) => {
        const token = localStorage.getItem('jwt') || null;
        const store = getStore();
        setStore({ loader: true })
        var formData = new FormData();
        formData.append("file", store.pago[0]);
        formData.append("option", "pagos");
        console.log(formData.getAll('file'));
        const resp = await fetch(
          `https://p136qqy5w2.execute-api.us-west-2.amazonaws.com/prd/sistema/upload`, //upload
          {
            method: "POST",
            headers: {
              'Authorization': `Bearer ${token}`,
            },
            body: formData,
          }
        );
        const dato = await resp.json();
        alert(dato.message);
        setStore({ loader: false })
      },

      postInteres: async (e) => {
        const token = localStorage.getItem('jwt') || null;
        const store = getStore();
        setStore({ loader: true })
        var formData = new FormData();
        formData.append("file", store.interes[0]);
        formData.append("option", "interes");
        console.log(formData.getAll('file'));
        const resp = await fetch(
          `https://p136qqy5w2.execute-api.us-west-2.amazonaws.com/prd/sistema/upload`, //upload
          {
            method: "POST",
            headers: {
              'Authorization': `Bearer ${token}`,
            },
            body: formData,
          }
        );
        const dato = await resp.json();
        alert(dato.message);
        setStore({ loader: false })
      },

      postIndicadores: async (e) => {
        const token = localStorage.getItem('jwt') || null;
        const store = getStore();
        setStore({ loader: true })
        var formData = new FormData();
        formData.append("file", store.indicador[0]);
        formData.append("option", "indicadores");
        console.log(formData.getAll('file'));
        const resp = await fetch(
          `https://p136qqy5w2.execute-api.us-west-2.amazonaws.com/prd/sistema/upload`, //upload
          {
            method: "POST",
            headers: {
              'Authorization': `Bearer ${token}`,
            },
            body: formData,
          }
        );
        const dato = await resp.json();
        alert(dato.message);
        setStore({ loader: false })
      },

      getFolio: async (e) => {
        const token = localStorage.getItem('jwt') || null;
        const store = getStore();
        store.afiliadosCalculoPre.shift()
        const data = Promise.all(
          store.afiliadosCalculoPre.map(
            async (item) =>
              await (
                await fetch(
                  // `https://p136qqy5w2.execute-api.us-west-2.amazonaws.com/prd/cal-folios?employer=${store.rut}&period=${item}`, 
                  `https://p136qqy5w2.execute-api.us-west-2.amazonaws.com/prd/folios?employer=${store.rut}&period=${item}`,      //folio,
                  {
                    headers: {
                      'Authorization': `Bearer ${token}`,
                    },
                  }
                )
              ).json()
          )
        )
          .then((data) => {
            let result = store.folio.concat(data);
            setStore({ folio: result, loading: false });
            console.log(store);
          });
      },

      getFolio2: async (e) => {
        const token = localStorage.getItem('jwt') || null;
        const store = getStore();
        store.afiliadosCalculo.shift()
        const data = Promise.all(
          store.afiliadosCalculo.map(
            async (item) =>
              await (
                await fetch(
                  `https://p136qqy5w2.execute-api.us-west-2.amazonaws.com/prd/cal-folios?employer=${store.rut}&period=${item}`,  //folio
                  {
                    headers: {
                      'Authorization': `Bearer ${token}`,
                    },
                  }
                )
              ).json()
          )
        )
          .then((data) => {
            let result = store.folio.concat(data);
            setStore({ folio: result, loading: false });
            console.log(store.folio);
          });
      },

      getUsers: async (e) => {
        const token = localStorage.getItem('jwt') || null;
        const store = getStore();
        const resp = await fetch(
          `https://p136qqy5w2.execute-api.us-west-2.amazonaws.com/prd/usuario`,  //clients
          {
            method: "GET",
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          }
        );
        const dato = await resp.json();
        setStore({ users: dato })
        console.log('llamada', store.users)
      },

      deleteUser: async (e) => {
        const token = localStorage.getItem('jwt') || null;
        const resp = await fetch(
          `https://p136qqy5w2.execute-api.us-west-2.amazonaws.com/prd/usuario?id=${e.user}&object=${e.object}`, //clients
          {
            method: "DELETE",
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          }
        );
        const dato = await resp.json();
        setStore({ resp: dato })
      },

      addUser: async (e) => {
        const token = localStorage.getItem('jwt') || null;
        console.log(e)
        const resp = await fetch(
          `https://p136qqy5w2.execute-api.us-west-2.amazonaws.com/prd/usuario`, //clients
          {
            method: "POST",
            headers: {
              'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(e),
          }
        );
        const dato = await resp.json();
        setStore({ resp: dato })
      },

      getPermissions: async (e) => {
        const token = localStorage.getItem('jwt') || null;
        const store = getStore();
        console.log(e);
        const resp = await fetch(
          `https://p136qqy5w2.execute-api.us-west-2.amazonaws.com/prd/usuario?id=${e}`,  //clients
          {
            method: "GET",
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          }
        );
        const dato = await resp.json();
        setStore({ permisos: dato })
        console.log(store.permisos)
      },

      handleForm: (e) => {
        setStore({ [e.target.name]: e.target.value });
      },

      handleFile: (e) => {
        console.log(e);
        setStore({
          asignacion: e,
        });
      },

      handleFilePago: (e) => {
        console.log(e);
        setStore({
          pago: e,
        });
      },

      handleFileInteres: (e) => {
        console.log(e);
        setStore({
          interes: e,
        });
      },

      getCalculo: async (e) => {
        const token = localStorage.getItem('jwt') || null;
        const store = getStore();
        const { rut, date, afiliadosCalculo, afiliadosCalculoPre } = store;

        if (afiliadosCalculo.length === 0 && afiliadosCalculoPre.length === 0) {
          console.log("error selecciona al menos un periodo");
        } else {
          let res = afiliadosCalculo.join();

          console.log(res);
          let pre = afiliadosCalculoPre.join();
          console.log(pre);
          console.log(
            `https://p136qqy5w2.execute-api.us-west-2.amazonaws.com/prd/calculadora?employer=${rut}&paymentdate=${date}&jperiods=${res}&pperiods=${pre}`
          );
          const resp = await fetch(
            `https://p136qqy5w2.execute-api.us-west-2.amazonaws.com/prd/calculadora?employer=${rut}&paymentdate=${date}&jperiods=${res}&pperiods=${pre}`, //calculo
            {
              method: "GET",
              headers: {
                'Authorization': `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          );
          const dato = await resp.json();
          console.log(dato);
          if (
            dato !==
            "Se ha proporcionado datos de consulta incompletos o incorrectos."
          ) {
            setStore({ afiliados: dato });
            console.log(dato)

            dato.carteras.map((item) => {
              if (item.id === "Judicial") {
                item.periodos.map((element, i) => {
                  item.periodos[i]["selected"] = false;
                  return item.periodos;
                });
                setStore({
                  afiliadosJudicial2: item.periodos,
                  detalleJudicial: item,
                });
                console.log(store.detalleJudicial);
              } else if (item.id === "Prejudicial") {
                item.periodos.map((element, i) => {
                  item.periodos[i]["selected"] = false;
                  return item.periodos;
                });
                setStore({ afiliadosPre2: item.periodos, detallePre: item });
                console.log(store.afiliadosPre2);
              }
            });
          } else {
            alert("Error");
          }
        }
      },

      handleChange: (e) => {
        setStore({ [e.target.name]: e.target.value });
      },

      handleSum: (total) => {
        const store = getStore();

        let res = store.montoNominal.concat(total);

        setStore({ montoNominal: res });
        console.log(store.montoNominal);
        let totalMonto = store.montoNominal.reduce(
          (total, obj) => obj + total,
          0
        );

        setStore({
          totalJudicial: totalMonto,
          montoNominal: [],
        });
      },

      handleSumPre: (total) => {
        const store = getStore();
        let res = store.montoNominalPre.concat(total);

        setStore({ montoNominalPre: res });

        let totalMonto = store.montoNominalPre.reduce(
          (total, obj) => obj + total,
          0
        );

        setStore({
          totalPreJudicial: totalMonto,
          montoNominalPre: [],
        });
      },

      handleReajuste: (
        reajuste,
        interes,
        recargoAfiliado,
        recargoAFP,
        interesProyectado,
        totalRecupero
      ) => {
        const store = getStore();
        if (!isNaN(reajuste)) {
          let resReajuste = store.reajuste.concat(reajuste);
          let res2 = store.interesJud.concat(interes);
          let res3 = store.recargoJud.concat(recargoAfiliado);
          let res4 = store.recargoAFPJud.concat(recargoAFP);
          let res5 = store.interesProyectadoJud.concat(interesProyectado);
          let res6 = store.totalRecuperoJud.concat(totalRecupero);
          setStore({
            reajuste: resReajuste,
            interesJud: res2,
            recargoJud: res3,
            recargoAFPJud: res4,
            interesProyectadoJud: res5,
            totalRecuperoJud: res6,
          });
          let totalReajuste = store.reajuste.reduce(
            (total, obj) => obj + total,
            0
          );
          let total2 = store.interesJud.reduce((total, obj) => obj + total, 0);
          let total3 = store.recargoJud.reduce((total, obj) => obj + total, 0);
          let total4 = store.recargoAFPJud.reduce(
            (total, obj) => obj + total,
            0
          );
          let total5 = store.interesProyectadoJud.reduce(
            (total, obj) => obj + total,
            0
          );
          let total6 = store.totalRecuperoJud.reduce(
            (total, obj) => obj + total,
            0
          );
          setStore({
            totalReajusteJud: totalReajuste,
            totalInteres: total2,
            totalRecargoJud: total3,
            totalRecargoAFPJud: total4,
            totalInteresProyectadoJud: total5,
            recuperoTotalJud: total6,
            reajuste: [],
            interesJud: [],
            recargoJud: [],
            recargoAFPJud: [],
            interesProyectadoJud: [],
            totalRecuperoJud: [],
          });
        }
      },

      handleReajustePre: (
        reajuste,
        interes,
        recargoAfiliado,
        recargoAFP,
        interesProyectado,
        totalRecupero
      ) => {
        const store = getStore();
        if (!isNaN(reajuste)) {
          let resReajuste = store.reajustePre.concat(reajuste);
          let res2 = store.interesPre.concat(interes);
          let res3 = store.recargoPre.concat(recargoAfiliado);
          let res4 = store.recargoAFPPre.concat(recargoAFP);
          let res5 = store.interesProyectadoPre.concat(interesProyectado);
          let res6 = store.totalRecuperoPre.concat(totalRecupero);
          setStore({
            reajustePre: resReajuste,
            interesPre: res2,
            recargoPre: res3,
            recargoAFPPre: res4,
            interesProyectadoPre: res5,
            totalRecuperoPre: res6,
          });
          let totalReajuste = store.reajustePre.reduce(
            (total, obj) => obj + total,
            0
          );
          let total2 = store.interesPre.reduce((total, obj) => obj + total, 0);
          let total3 = store.recargoPre.reduce((total, obj) => obj + total, 0);
          let total4 = store.recargoAFPPre.reduce(
            (total, obj) => obj + total,
            0
          );
          let total5 = store.interesProyectadoPre.reduce(
            (total, obj) => obj + total,
            0
          );
          let total6 = store.totalRecuperoPre.reduce(
            (total, obj) => obj + total,
            0
          );
          setStore({
            totalReajustePre: totalReajuste,
            totalInteresPre: total2,
            totalRecargoPre: total3,
            totalRecargoAFPPre: total4,
            totalInteresProyectadoPre: total5,
            recuperoTotalPre: total6,
            reajustePre: [],
            interesPre: [],
            recargoPre: [],
            recargoAFPPre: [],
            interesProyectadoPre: [],
            totalRecuperoPre: [],
          });
        }
      },


      resetAll: (e) => {
        setStore({
          totalJudicial: 0,
          rut: "",
          afiliadosPre: [],
          afiliadosJudicial: [],
          afiliadosPre2: [],
          afiliadosJudicial2: [],
          totalPreJudicial: 0,
          totalReajusteJud: 0,
          totalPago: 0,
          totalPagoPre: 0,
          totalInteres: 0,
          totalRecargoJud: 0,
          totalRecargoAFPJud: 0,
          totalInteresProyectadoJud: 0,
          recuperoTotalJud: 0,
          totalReajustePre: 0,
          totalInteresPre: 0,
          totalRecargoPre: 0,
          totalRecargoAFPPre: 0,
          totalInteresProyectadoPre: 0,
          recuperoTotalPre: 0,
          interesProyectadoJud: [],
          totalRecuperoJud: [],
          interesJud: [],
          recargoJud: [],
          recargoAFPJud: [],
          reajuste: [],
          interesProyectadoPre: [],
          totalRecuperoPre: [],
          interesPre: [],
          recargoPre: [],
          recargoAFPPre: [],
          reajustePre: [],
          montoNominal: [],
          montoNominalPre: [],
          afiliadosCalculo: [0],
          afiliadosCalculoPre: [0],
        });
      },

      resetFolio: (e) => {
        setStore({
          folio: [], loading: true
        })

      },


      handleFileIndicador: (e) => {

        setStore({
          indicador: e,
        });
      },

      handleCheckbox: (e) => {
        const store = getStore();
        let checked = e.target.checked;
        let newAfiliados = [...store.afiliadosJudicial];

        newAfiliados.map((element, i) => {
          console.log(element.id.toString());
          console.log(i);
          if (e.target.id === element.id.toString()) {
            element.selected = checked;
            if (
              element.selected === true &&
              store.afiliadosCalculo.includes(element) === false
            ) {
              let result = store.afiliadosCalculo.concat(element.id.toString());
              setStore({ afiliadosCalculo: result });
            } else {
              let index = store.afiliadosCalculo.findIndex(
                (index) => index === element
              );
              store.afiliadosCalculo.splice(index, 1);
              setStore({ afiliadosCalculo: store.afiliadosCalculo });
            }
          }
          return element;
        });
        setStore({ afiliados2: newAfiliados });
        console.log(store.afiliadosCalculo);
      },

      handleCheckboxPre: (e) => {
        const store = getStore();
        let checked = e.target.checked;
        let newAfiliados = [...store.afiliadosPre];

        newAfiliados.map((element, i) => {
          console.log(element.id);
          if (e.target.id === element.id.toString()) {
            element.selected = checked;
            if (
              checked === true &&
              store.afiliadosCalculoPre.includes(element) === false
            ) {
              let result = store.afiliadosCalculoPre.concat(
                element.id.toString()
              );
              setStore({ afiliadosCalculoPre: result });
            } else {
              let index = store.afiliadosCalculoPre.findIndex(
                (index) => index === element
              );
              store.afiliadosCalculoPre.splice(index, 1);
              setStore({ afiliadosCalculoPre: store.afiliadosCalculoPre });
            }
          }
          return element;
        });
        setStore({ afiliados2: newAfiliados });
        console.log(store.afiliadosCalculoPre);
      },

      handleAllCheckbox: (e) => {
        const store = getStore();
        let checked = e.target.checked;
        let newAfiliados = [...store.afiliados];

        newAfiliados.map((element, i) => {
          element.selected = checked;
          if (
            checked === true &&
            store.afiliadosCalculo.includes(element) === false
          ) {
            let result = store.afiliadosCalculo.concat(element);
            setStore({ afiliadosCalculo: result });
          } else if (
            checked === false &&
            store.afiliadosCalculo.includes(element) === true
          ) {
            setStore({ afiliadosCalculo: [] });
          }
          return element;
        });
        setStore({ afiliados2: newAfiliados });
        console.log(store.afiliadosCalculo);
      },

      handleAllCheckboxFiltered: (e) => {
        const store = getStore();
        let checked = e.target.checked;
        let newAfiliados = [...store.filterPeriodo];

        newAfiliados.map((element, i) => {
          element.selected = checked;
          if (
            checked === true &&
            store.afiliadosCalculo.includes(element) === false
          ) {
            let result = store.afiliadosCalculo.concat(element);
            setStore({ afiliadosCalculo: result });
          } else if (
            checked === false &&
            store.afiliadosCalculo.includes(element) === true
          ) {
            setStore({ afiliadosCalculo: [] });
          }
          return element;
        });
        setStore({ afiliados2: newAfiliados });
        console.log(store.afiliadosCalculo);
      },

      setTotal: (e) => {
        const store = getStore();
        const { recuperoTotalJud, detalleJudicial } = store;
        let result =
          recuperoTotalJud +
          detalleJudicial.honorarios +
          detalleJudicial.costas;

        setStore({ totalPago: result });
        console.log(store.totalPago);
      },

      setTotalPre: (e) => {
        const store = getStore();
        const { recuperoTotalPre, detallePre } = store;
        let result =
          recuperoTotalPre + detallePre.honorarios + detallePre.costas;

        setStore({ totalPagoPre: result });
      },

      setDate: (e) => {
        const store = getStore();
        console.log(e);
        let formattedDate = e.split("-").join("");
        console.log(formattedDate);

        setStore({ date: formattedDate });
      },

      login: async (email, password, idToken) => {
        // const resp = await fetch(
        //   `https://afphabitat.hmycia.com/backend/auth/me`, //login
        //   {
        //     method: "POST",
        //     headers: {
        //       "Content-Type": "application/json",
        //     },
        //     body: JSON.stringify({
        //       email,
        //       password,
        //     }),
        //   }
        // )
        console.log(idToken)
        const { token } = idToken
        setStore({ currentUser: email })
        localStorage.setItem('jwt', idToken)
      },

      authMe: async () => {
        const token = localStorage.getItem('jwt');
        console.log(token)
        try {
          const resp = await fetch(
            // `http://localhost:8000/backend/auth/me`, //auth myself
            "https://afphabitat.hmycia.com/backend/auth/me",
            {
              method: "GET",
              headers: {
                'Authorization': `Bearer ${token}`,
              },
            }
          )
          console.log(resp)
          const data = await resp.json()
          console.log(data)
          if (data) {
            setStore({ currentUser: data.user })
            setStore({ permisos: data.permisos })

          }
        }
        catch (err) {
          console.log(err)
        }
      },

      logout: () => {
        localStorage.removeItem('jwt')
        setStore({ currentUser: '' })
      },

      generarPdf: async (data) => {
        const token = localStorage.getItem('jwt') || null
        try {
          console.log({ ...data })
          const resp = await fetch(
            `https://p136qqy5w2.execute-api.us-west-2.amazonaws.com/prd/generar-pdf`,
            {
              method: "POST",
              headers: {
                'Authorization': `Bearer ${token}`,
              },
              body: JSON.stringify({
                ...data
              }),
            }
          )
          console.log(resp)
          const pdf = await resp.json()

          return pdf
        }
        catch (err) {
          console.log(err)
        }
      }
    },
  };
};

export default getState;
