import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { Context } from "../AppContext";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import StyledExcel from "../styles/StyledExcel";
import StyledTitle from "../styles/StyledTitle";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import MainNavbar from "../components/MainNavbar";
import Container from "@material-ui/core/Container";
import StyledButton from "../styles/StyledButton";
import Pdf from "../components/Pdf";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
    MuiTableCell: {
      fontSize: "26px !important",
    },
    color: "#fafafa !important",
  },
  table: {
    marginTop: "60px",
    marginLeft: "30px",
  },
  table2: {
    marginTop: "20px",
    marginLeft: "30px",
  },
});

function Row(props) {
  const {
    item,
    total,
    reajuste,
    interes,
    recargoAfiliado,
    recargoAFP,
    interesProyectado,
    totalRecupero,
  } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
  const { store, actions } = useContext(Context);

  useEffect(() => {
    actions.handleSum(total);
  }, [total]);

  useEffect(() => {
    actions.handleReajuste(
      reajuste,
      interes,
      recargoAfiliado,
      recargoAFP,
      interesProyectado,
      totalRecupero
    );
    actions.setTotal();
  }, [reajuste]);

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          style={{ fontSize: "20px", fontWeight: "bold" }}
        >
          {item.id}
        </TableCell>

        <TableCell
          align="center"
          style={{ fontSize: "20px", fontWeight: "bold" }}
        >
          Total: {total}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Rut Afiliado</TableCell>
                    <TableCell>Monto Nominal</TableCell>
                    <TableCell>Reajuste</TableCell>
                    <TableCell>Interés</TableCell>
                    <TableCell>Recargo Afiliado</TableCell>
                    <TableCell>Recargo AFP</TableCell>
                    <TableCell>Interés Proyectado</TableCell>
                    <TableCell>Total Recupero</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {item.deudas.map((afiliado, i) => (
                    <TableRow key={i}>
                      <TableCell component="th" scope="row">
                        {afiliado.idPersona}
                      </TableCell>
                      <TableCell>
                        $ {Math.round(afiliado.montoNominal)}
                      </TableCell>
                      <TableCell>$ {Math.round(afiliado.reajuste)}</TableCell>
                      <TableCell>$ {Math.round(afiliado.interes)}</TableCell>
                      <TableCell>
                        $ {Math.round(afiliado.recargoAfiliado)}
                      </TableCell>
                      <TableCell>$ {Math.round(afiliado.recargoAFP)}</TableCell>
                      <TableCell>
                        $ {Math.round(afiliado.interesProyectado)}
                      </TableCell>
                      <TableCell>
                        $ {Math.round(afiliado.totalRecupero)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
function RowPre(props) {
  const {
    item,
    total,
    reajuste,
    interes,
    recargoAfiliado,
    recargoAFP,
    interesProyectado,
    totalRecupero,
  } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
  const { store, actions } = useContext(Context);

  useEffect(() => {
    actions.handleSumPre(total);
  }, [total]);

  useEffect(() => {
    actions.handleReajustePre(
      reajuste,
      interes,
      recargoAfiliado,
      recargoAFP,
      interesProyectado,
      totalRecupero
    );
    actions.setTotalPre();
  }, [reajuste]);

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          style={{ fontSize: "20px", fontWeight: "bold" }}
        >
          {item.id}
        </TableCell>

        <TableCell
          align="center"
          style={{ fontSize: "20px", fontWeight: "bold" }}
        >
          Total: {total}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Rut Afiliado</TableCell>
                    <TableCell>Monto Nominal</TableCell>
                    <TableCell>Reajuste</TableCell>
                    <TableCell>Interés</TableCell>
                    <TableCell>Recargo Afiliado</TableCell>
                    <TableCell>Recargo AFP</TableCell>
                    <TableCell>Interés Proyectado</TableCell>
                    <TableCell>Total Recupero</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {item.deudas.map((afiliado, i) => (
                    <TableRow key={i}>
                      <TableCell component="th" scope="row">
                        {afiliado.idPersona}
                      </TableCell>
                      <TableCell>
                        $ {Math.round(afiliado.montoNominal)}
                      </TableCell>
                      <TableCell>$ {Math.round(afiliado.reajuste)}</TableCell>
                      <TableCell>$ {Math.round(afiliado.interes)}</TableCell>
                      <TableCell>
                        $ {Math.round(afiliado.recargoAfiliado)}
                      </TableCell>
                      <TableCell>$ {Math.round(afiliado.recargoAFP)}</TableCell>
                      <TableCell>
                        $ {Math.round(afiliado.interesProyectado)}
                      </TableCell>
                      <TableCell>
                        $ {Math.round(afiliado.totalRecupero)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#25AAE2",
    color: " black",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function CollapsibleTable() {
  const classes = useRowStyles();
  const { store, actions } = useContext(Context);

  useEffect(() => {
    actions.resetFolio();
  }, []);

  let day = store.date.slice(6, 8);
  let month = store.date.slice(4, 6);
  let year = store.date.slice(0, 4);
  let fecha = `${day}-${month}-${year}`;

  return (
    <>
      <MainNavbar />
      <StyledTitle another>CALCULO DEUDA PREVISIONAL</StyledTitle>

      <Container maxWidth="md">
        <StyledTitle secondary>
          Empleador: {store.afiliados.razonSocial}
        </StyledTitle>
        <StyledTitle third>Rut: {store.afiliados.idEmpleador}</StyledTitle>
        <TableContainer
          component={Paper}
          className={classes.table2}
          style={{ overflowX: "none" }}
        >
          <Typography
            className={classes.title}
            variant="h6"
            id="tableTitle"
            component="div"
            style={{
              fontSize: "22px",
              fontWeight: "800",
              textAlign: "center",
              backgroundColor: "#25AAE2",
            }}
          >
            RESUMEN DEUDA CARTERA JUDICIAL
            <Link to="/form2">
              <StyledButton primary pdf onClick={(e) => actions.getFolio2(e)}>
                Generar PDF
              </StyledButton>
            </Link>
            <TableContainer
              component={Paper}
              
              style={{
                display: "inline-block",
                width: "210px",
                position: "absolute",
                left: "0px",
                marginLeft: "10px",
                fontSize: '14px'
              }}
            >
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Fecha de pago</StyledTableCell>
                    <StyledTableCell align="right">Valor UF</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <StyledTableCell>{fecha}</StyledTableCell>
                    <StyledTableCell align="right">
                      {store.afiliados.ufValue}
                    </StyledTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Typography>

          <Table aria-label="simple table">
            <TableBody>
              <TableRow>
                <TableCell style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Monto Nominal
                </TableCell>
                <TableCell style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Reajuste
                </TableCell>
                <TableCell style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Interés
                </TableCell>
                <TableCell style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Recargo Afiliado
                </TableCell>
                <TableCell style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Recargo AFP
                </TableCell>
                <TableCell style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Interés Proyectado
                </TableCell>
                <TableCell style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Total Recupero
                </TableCell>
                <TableCell style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Honorarios de Cobranza
                </TableCell>
                <TableCell style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Costas Procesales
                </TableCell>
                <TableCell style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Total a pagar
                </TableCell>
              </TableRow>
              <TableCell component="th" scope="row">
                ${Math.round(store.totalJudicial)}
              </TableCell>
              <TableCell>${Math.round(store.totalReajusteJud)}</TableCell>
              <TableCell>${Math.round(store.totalInteres)}</TableCell>
              <TableCell>${Math.round(store.totalRecargoJud)}</TableCell>
              <TableCell>${Math.round(store.totalRecargoAFPJud)}</TableCell>
              <TableCell>
                ${Math.round(store.totalInteresProyectadoJud)}
              </TableCell>
              <TableCell>${Math.round(store.recuperoTotalJud)}</TableCell>
              {/* <TableCell>
                ${Math.round(store.detalleJudicial.honorarios)}
              </TableCell> */}
              <TableCell>${Math.round(store.totalJudicial*0.1)}</TableCell>
              <TableCell>${Math.round(store.detalleJudicial.costas)}</TableCell>
              {/* <TableCell>${Math.round(store.totalPago)}</TableCell> */}
              <TableCell>${
                  Math.round(store.totalJudicial) +
                  Math.round(store.totalInteres) +
                  Math.round(store.totalRecargoJud) +
                  Math.round(store.totalRecargoAFPJud) +
                  Math.round(store.totalInteresProyectadoJud) +
                  Math.round(store.totalJudicial*0.1)
                }</TableCell>
            </TableBody>
          </Table>
        </TableContainer>
        <TableContainer
          component={Paper}
          className={classes.table}
          style={{ overflowX: "none" }}
        >
          <Typography
            className={classes.title}
            variant="h6"
            id="tableTitle"
            component="div"
            style={{
              fontSize: "22px",
              fontWeight: "800",
              textAlign: "center",
              backgroundColor: "#25AAE2",
            }}
          >
            RESUMEN DEUDA CARTERA PREJUDICIAL
            <Link to="/form">
              <StyledButton primary pdf onClick={(e) => actions.getFolio(e)}>
                Generar PDF
              </StyledButton>
            </Link>
          </Typography>

          <Table aria-label="simple table">
            <TableBody>
              <TableRow>
                <TableCell style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Monto Nominal
                </TableCell>
                <TableCell style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Reajuste
                </TableCell>
                <TableCell style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Interés
                </TableCell>
                <TableCell style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Recargo Afiliado
                </TableCell>
                <TableCell style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Recargo AFP
                </TableCell>
                <TableCell style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Interés Proyectado
                </TableCell>
                <TableCell style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Total Recupero
                </TableCell>
                <TableCell style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Honorarios de Cobranza
                </TableCell>
                <TableCell style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Costas Procesales
                </TableCell>
                <TableCell style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Total a pagar
                </TableCell>
              </TableRow>
              <TableCell component="th" scope="row">
                ${Math.round(store.totalPreJudicial)}
              </TableCell>
              <TableCell>${Math.round(store.totalReajustePre)}</TableCell>
              <TableCell>${Math.round(store.totalInteresPre)}</TableCell>
              <TableCell>${Math.round(store.totalRecargoPre)}</TableCell>
              <TableCell>${Math.round(store.totalRecargoAFPPre)}</TableCell>
              <TableCell>
                ${Math.round(store.totalInteresProyectadoPre)}
              </TableCell>
              <TableCell>${Math.round(store.recuperoTotalPre)}</TableCell>
              {/* <TableCell>${Math.round(store.detallePre.honorarios)}</TableCell> */}
              <TableCell>${Math.round(store.totalPreJudicial*0.1)}</TableCell>
              <TableCell>${Math.round(store.detallePre.costas)}</TableCell>
              {/* <TableCell>${Math.round(store.totalPagoPre)}</TableCell> */}
              <TableCell>${
                  Math.round(store.totalPreJudicial) +
                  Math.round(store.totalInteresPre) +
                  Math.round(store.totalRecargoPre) +
                  Math.round(store.totalPreJudicial*0.1) 
                }</TableCell>
            </TableBody>
          </Table>
        </TableContainer>
        <TableContainer component={Paper} className={classes.table}>
          <Typography
            className={classes.title}
            variant="h6"
            id="tableTitle"
            component="div"
            style={{
              fontSize: "22px",
              fontWeight: "800",
              textAlign: "center",
              backgroundColor: "#25AAE2",
            }}
          >
            DETALLE CARTERA JUDICIAL
          </Typography>
          <Table aria-label="collapsible table">
            <TableBody>
              {store.rut &&
                store.afiliadosJudicial2.map((item, i) => {
                  let total = item.deudas.reduce(
                    (total, obj) => obj.montoNominal + total,
                    0
                  );

                  let reajusteJud = item.deudas.reduce(
                    (total, obj) => obj.reajuste + total,
                    0
                  );

                  let interes = item.deudas.reduce(
                    (total, obj) => obj.interes + total,
                    0
                  );

                  let recargoAfiliado = item.deudas.reduce(
                    (total, obj) => obj.recargoAfiliado + total,
                    0
                  );

                  let recargoAFP = item.deudas.reduce(
                    (total, obj) => obj.recargoAFP + total,
                    0
                  );
                  let interesProyectado = item.deudas.reduce(
                    (total, obj) => obj.interesProyectado + total,
                    0
                  );
                  let totalRecupero = item.deudas.reduce(
                    (total, obj) => obj.totalRecupero + total,
                    0
                  );
                  return (
                    <Row
                      key={i}
                      item={item}
                      total={total}
                      reajuste={reajusteJud}
                      interes={interes}
                      recargoAfiliado={recargoAfiliado}
                      recargoAFP={recargoAFP}
                      interesProyectado={interesProyectado}
                      totalRecupero={totalRecupero}
                      style={{ fontSize: "22px" }}
                    />
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TableContainer
          component={Paper}
          className={classes.table}
          style={{ marginBottom: "60px" }}
        >
          <Typography
            className={classes.title}
            variant="h6"
            id="tableTitle"
            component="div"
            style={{
              fontSize: "22px",
              fontWeight: "800",
              textAlign: "center",
              backgroundColor: "#25AAE2",
            }}
          >
            DETALLE CARTERA PREJUDICIAL
          </Typography>
          <Table aria-label="collapsible table">
            <TableBody>
              {store.rut &&
                store.afiliadosPre2.map((item, i) => {
                  let total = item.deudas.reduce(
                    (total, obj) => obj.montoNominal + total,
                    0
                  );
                  let reajusteJud = item.deudas.reduce(
                    (total, obj) => obj.reajuste + total,
                    0
                  );

                  let interes = item.deudas.reduce(
                    (total, obj) => obj.interes + total,
                    0
                  );

                  let recargoAfiliado = item.deudas.reduce(
                    (total, obj) => obj.recargoAfiliado + total,
                    0
                  );

                  let recargoAFP = item.deudas.reduce(
                    (total, obj) => obj.recargoAFP + total,
                    0
                  );
                  let interesProyectado = item.deudas.reduce(
                    (total, obj) => obj.interesProyectado + total,
                    0
                  );
                  let totalRecupero = item.deudas.reduce(
                    (total, obj) => obj.totalRecupero + total,
                    0
                  );
                  return (
                    <RowPre
                      key={i}
                      item={item}
                      total={total}
                      reajuste={reajusteJud}
                      interes={interes}
                      recargoAfiliado={recargoAfiliado}
                      recargoAFP={recargoAFP}
                      interesProyectado={interesProyectado}
                      totalRecupero={totalRecupero}
                      style={{ fontSize: "22px" }}
                    />
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
      <Link to="/main">
        <StyledButton onClick={(e) => actions.resetAll(e)}>Volver</StyledButton>
      </Link>
    </>
  );
}
