import React, { useState, useContext, useEffect} from 'react';
import { Context } from "../AppContext";
import DatePicker from 'react-date-picker';

export default function Calendar() {
  const { store, actions } = useContext(Context);
  const [value, onChange] = useState(new Date());

  useEffect(() => {
    if(value) actions.setDate(value.toISOString().slice(0,10))
  }, [value])


  return (
    <div style={{display: "inline-block", marginLeft: '200px'}}>
      <DatePicker
        onChange={onChange}
        value={value}
      />
    </div>
  );
}