import React, { Component } from 'react';
import Router from './components/Router'
import StyledBody from './styles/StyledBody';
import injectContext from './AppContext';

class App extends Component {
  render() {
    return (
      <div >
        <StyledBody />
        <Router />
      </div>
    );
  }
}

export default injectContext(App);