import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { Context } from "../AppContext";
import MainNavbar from "../components/MainNavbar";
import FolderIcon from "@material-ui/icons/Folder";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import Grid from "@material-ui/core/Grid";
import AssessmentIcon from '@material-ui/icons/Assessment';
import Typography from "@material-ui/core/Typography";
import StyledTitle from "../styles/StyledTitle";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-evenly",
    "& > *": {
      marginTop: theme.spacing(8),
      marginLeft: theme.spacing(12),
      marginRight: theme.spacing(10),
    },
  },
  root2: {
    display: "flex",
    justifyContent: "space-evenly",
    "& > *": {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(8),
      marginRight: theme.spacing(4),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    marginLeft: '70px',
    backgroundColor: "#25AAE2",
    cursor: "pointer",
  },
  large2: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    backgroundColor: "#25AAE2",
    marginLeft: '90px',
    cursor: "pointer",
  },

  hidden: {
    display: "none",
  },
}));

export default function Menu() {
  const classes = useStyles();
  const { store, actions } = useContext(Context);

  useEffect(() => {
    actions.getUsers();
  }, []);

  return (
    <div>
      <MainNavbar />
      <div className={classes.root}>
      <Grid container direction="row" justify="space-around" alignItems="center" spacing={6}>
          <Grid item xs={4}>
            <div
              className={
                store.permisos.includes("Calculadora-Config")
                  ? ""
                  : classes.hidden
              }
            >
              <Link to="/uploader">
                <Avatar className={classes.large}>
                  <FolderIcon style={{ fontSize: "40px" }} />
                </Avatar>
              </Link>
              <StyledTitle menu>Carga de archivos</StyledTitle>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div
              className={
                store.permisos.includes("Calculadora") ? "" : classes.hidden
              }
            >
              <Link to="/main">
                <Avatar className={classes.large}>
                  <BusinessCenterIcon style={{ fontSize: "40px" }} />
                </Avatar>
              </Link>
              <StyledTitle menu>Calculo de interés</StyledTitle>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div
              className={
                store.permisos.includes("Usuarios") ? "" : classes.hidden
              }
            >
              <Link to="/admin">
                <Avatar className={classes.large2}>
                  <PeopleAltIcon style={{ fontSize: "40px" }} />
                </Avatar>
              </Link>
              <StyledTitle menu>Administrador de Usuarios</StyledTitle>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div
              className={
                store.permisos.includes("Global-Config") ? "" : classes.hidden
              }
            >
              <Link to="/indicadores">
                <Avatar className={classes.large}>
                  <AssessmentIcon style={{ fontSize: "40px" }} />
                </Avatar>
              </Link>
              <StyledTitle menu>Carga de indicadores</StyledTitle>
            </div>
          </Grid>
      </Grid>
      </div>
      <div className={classes.root2}>
        <div className={store.permisos.length === 0 ? "" : classes.hidden}>
          <StyledTitle menu>No tienes permisos</StyledTitle>
        </div>
      </div>
    </div>
  );
}
