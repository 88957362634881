import React, { useContext, useEffect } from "react";
import { Context } from "../AppContext";
import { DropzoneArea } from "material-ui-dropzone";
import MainNavbar from "../components/MainNavbar";
import Container from "@material-ui/core/Container";
import StyledButton from "../styles/StyledButton";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function GlobalUploader() {
  const { store, actions } = useContext(Context);
  return (
    <div>
      <MainNavbar />
      {store.loader && (
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <CircularProgress />
        </div>
      )}
      {!store.loader && 
      <Container maxWidth="md" style={{marginBottom: '40px'}}>
        <DropzoneArea
          dropzoneText={"Agrega el archivo de indicadores aquí o haz click"}
          name='asignacion'
          onChange={(e) => actions.handleFileIndicador(e)}
          acceptedFiles={['.xls']}
        >
        </DropzoneArea>
        <StyledButton primary pdf onClick={(e) => actions.postIndicadores()}>Enviar</StyledButton>
      </Container>
      }
    </div>
  );
}
